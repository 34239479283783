import React, { FC, useContext, useCallback, useMemo } from "react";
import { TimerContext } from "contexts/timerContext";

const TimerButtons: FC = () => {
  const { dispatch } = useContext(TimerContext);

  const start = useCallback(() => dispatch({ type: "START" }), [dispatch]);
  const stop = useCallback(() => dispatch({ type: "STOP" }), [dispatch]);
  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, [dispatch]);

  return useMemo(
    () => (
      <div className="flex">
        <button
          type="button"
          className="w-full border-l border-t border-b text-base font-medium rounded-l-md text-white bg-blue-500 px-4 py-2"
          onClick={start}
        >
          Start
        </button>
        <button
          type="button"
          className="w-full border text-base font-medium text-white bg-blue-500 px-4 py-2"
          onClick={stop}
        >
          Stop
        </button>
        <button
          type="button"
          className="w-full border-r border-t border-b text-base font-medium rounded-r-md text-white bg-blue-500 px-4 py-2"
          onClick={reset}
        >
          Reset
        </button>
      </div>
    ),
    [start, stop, reset]
  );
};

export default TimerButtons;
