import React, { FC } from "react";

type Props = {
  label: string;
  onclick: () => void;
};

const Button: FC<Props> = ({ onclick, label }) => (
  <button
    type="button"
    className="w-full border text-base font-medium rounded-md text-white bg-blue-500 px-4 py-2"
    onClick={onclick}
  >
    {label}
  </button>
);

export default Button;
