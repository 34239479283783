import React, { FC, useReducer } from "react";
import AfterReading from "components/organisms/AfterReading";
import MiddleReading from "components/organisms/MiddleReading";
import BeforeReading from "components/organisms/BeforeReading";
import InitialElement from "components/organisms/InitialElement";
import {
  initialTimeLineState,
  TimeLineContext,
  timeLineReducer,
} from "contexts/timeLineContext";
import CopyButton from "components/atoms/CopyButton";
import { useForm } from "react-hook-form";
import copy from "copy-to-clipboard";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const copyHandler = (data: any) => {
  // eslint-disable-next-line no-console
  console.log(data);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  let text = `${data.initialElement as string}\n`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const beforeReadingElementContent = data.beforeReadingElement;
  if (typeof beforeReadingElementContent === "string") {
    text += `\n${beforeReadingElementContent}\n`;
  }

  let currentId = 2;
  while (true) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const middleReadingElementContent =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      data[`middleReadingElement_${currentId}`];
    if (typeof middleReadingElementContent === "string") {
      text += `\n${middleReadingElementContent}\n`;
      currentId += 1;
    } else {
      break;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const afterEadingElementContent =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      data[`afterReadingElement_${currentId}`];
    if (typeof afterEadingElementContent === "string") {
      text += `\n${afterEadingElementContent}\n`;
      currentId += 1;
    } else {
      break;
    }
  }
  copy(text);
};

const TimeLine: FC = () => {
  const [state, dispatch] = useReducer(timeLineReducer, initialTimeLineState);

  const { register, handleSubmit } = useForm();
  const onSubmit = copyHandler;

  return (
    <TimeLineContext.Provider value={{ state, dispatch }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container flex flex-col mx-auto max-w-md space-y-6">
          {state.timeLine.map((element) => {
            switch (element.type) {
              case "INITIAL":
                return <InitialElement key={element.id} register={register} />;
              case "BEFORE_READING":
                return <BeforeReading key={element.id} register={register} />;
              case "MIDDLE_READING":
                return (
                  <MiddleReading
                    key={element.id}
                    id={element.id}
                    register={register}
                  />
                );
              case "AFTER_READING":
                return (
                  <AfterReading
                    key={element.id}
                    id={element.id}
                    register={register}
                  />
                );
              default:
                return <></>;
            }
          })}
        </div>
        <CopyButton />
      </form>
    </TimeLineContext.Provider>
  );
};

export default TimeLine;
