import React, { FC } from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

type Props = {
  placeholder: string;
  register: UseFormRegister<FieldValues>;
  name: string;
};

const TextBox: FC<Props> = ({ placeholder, register, name }) => (
  <textarea
    placeholder={placeholder}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...register(name)}
    className="w-full h-40 rounded-lg border border-gray-300 py-2 px-4 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent"
  />
);

export default TextBox;
