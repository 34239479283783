import React, { FC, useContext, useCallback, useMemo } from "react";
import { TimeLineContext } from "contexts/timeLineContext";
import { TimerContext } from "contexts/timerContext";
import Button from "components/atoms/Button";

const NextSession: FC<{ disableAfterReading: () => void }> = ({
  disableAfterReading,
}) => {
  const timeLineDispatch = useContext(TimeLineContext).dispatch;
  const timerDispatch = useContext(TimerContext).dispatch;

  const nextSession = useCallback(() => {
    timeLineDispatch({ type: "READ_AGAIN" });
    timerDispatch({ type: "START" });
    disableAfterReading();
  }, [timeLineDispatch, timerDispatch, disableAfterReading]);

  return useMemo(() => <Button label="Next Session" onclick={nextSession} />, [
    nextSession,
  ]);
};

export default NextSession;
