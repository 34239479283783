import React, { FC, useState, useContext, useCallback } from "react";
import { TimeLineContext } from "contexts/timeLineContext";
import Button from "components/atoms/Button";
import ButtonDisabled from "components/atoms/ButtonDisabled";
import { UseFormRegister, FieldValues } from "react-hook-form";

type Props = {
  register: UseFormRegister<FieldValues>;
};

const InitialElement: FC<Props> = ({ register }) => {
  const [active, setActive] = useState(true);

  const { dispatch } = useContext(TimeLineContext);

  const ok = useCallback(() => {
    dispatch({ type: "INPUT_TITLE" });
    setActive(false);
  }, [dispatch, setActive]);

  if (active) {
    return (
      <div
        id="initialElement"
        className="flex-none relative rounded-lg shadow-xl bg-white p-4"
      >
        <h2 className="text-lg leading-6 font-medium text-black">
          本のタイトルは？
        </h2>
        <input
          type="text"
          placeholder="本のタイトル"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("initialElement")}
          className="w-full rounded-lg border border-gray-300 py-2 px-4 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent"
        />
        <Button label="OK" onclick={ok} />
      </div>
    );
  }

  return (
    <div
      id="initialElement"
      className="flex-none relative rounded-lg shadow-xl bg-white p-4"
    >
      <h2 className="text-lg leading-6 font-medium text-black">
        本のタイトルは？
      </h2>
      <input
        type="text"
        placeholder="本のタイトル"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("initialElement")}
        className="w-full rounded-lg border border-gray-300 py-2 px-4 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent"
      />
      <ButtonDisabled label="OK" />
    </div>
  );
};

export default InitialElement;
