import { createContext, Dispatch, Reducer } from "react";

export type TimeLineAction = {
  type: "INPUT_TITLE" | "INPUT_BEFORE_READING_NOTE" | "TIMEOUT" | "READ_AGAIN";
};

export type TimeLineElementTypes =
  | "INITIAL"
  | "BEFORE_READING"
  | "MIDDLE_READING"
  | "AFTER_READING";

type TimeLineElement = {
  id: number;
  type: TimeLineElementTypes;
};

export type TimeLineState = {
  timeLine: TimeLineElement[];
  currentId: number;
};

type IContext = {
  state: TimeLineState;
  dispatch: Dispatch<TimeLineAction>;
};

export const initialTimeLineState: TimeLineState = {
  timeLine: [{ id: 0, type: "INITIAL" }],
  currentId: 0,
};

export const TimeLineContext = createContext<IContext>({
  state: initialTimeLineState,
  dispatch: () => null,
});

export const timeLineReducer: Reducer<TimeLineState, TimeLineAction> = (
  state,
  action
) => {
  const newId = state.currentId + 1;
  switch (action.type) {
    case "INPUT_TITLE":
      return {
        timeLine: [...state.timeLine, { id: newId, type: "BEFORE_READING" }],
        currentId: newId,
      };
    case "INPUT_BEFORE_READING_NOTE":
      return {
        timeLine: [...state.timeLine, { id: newId, type: "MIDDLE_READING" }],
        currentId: newId,
      };
    case "TIMEOUT":
      return {
        timeLine: [...state.timeLine, { id: newId, type: "AFTER_READING" }],
        currentId: newId,
      };
    case "READ_AGAIN":
      return {
        timeLine: [...state.timeLine, { id: newId, type: "MIDDLE_READING" }],
        currentId: newId,
      };
    default:
      return initialTimeLineState;
  }
};
