import React, { FC, useReducer, memo } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import TimeLine from "components/organisms/TimeLine";
import { parse } from "query-string";
import { TimerContext, timerReducer } from "contexts/timerContext";
import initialLimitParser from "utils/initialLimitParser";
import TimerOperations from "utils/timerOperations";

const MemoizedTimeLine = memo(TimeLine);

const App: FC = () => {
  const { search } = useLocation();

  // pass initial limit with query parameter for testability
  const limit = initialLimitParser(parse(search).t);

  const [timerState, timerDispatch] = useReducer(timerReducer, {
    timer: new TimerOperations(limit * 1000),
  });

  return (
    <TimerContext.Provider
      value={{ state: timerState, dispatch: timerDispatch }}
    >
      {timerState.timer.isTimerRunning ? (
        <div
          id="timedReadingAppBackgroundRunning"
          className="min-h-screen bg-gradient-to-r from-red-200 to-red-100"
        >
          <MemoizedTimeLine />
        </div>
      ) : (
        <div
          id="timedReadingAppBackgroundNotRunning"
          className="min-h-screen bg-gradient-to-r from-blue-200 to-red-100"
        >
          <MemoizedTimeLine />
        </div>
      )}
    </TimerContext.Provider>
  );
};

export default App;
