import React, { FC } from "react";

const TimerButtonsDisabled: FC = () => (
  <div className="flex">
    <button
      type="button"
      disabled
      className="w-full border-l border-t border-b text-base font-medium rounded-l-md text-white bg-blue-500 px-4 py-2 cursor-not-allowed"
    >
      Start
    </button>
    <button
      type="button"
      disabled
      className="w-full border text-base font-medium text-white bg-blue-500 px-4 py-2 cursor-not-allowed"
    >
      Stop
    </button>
    <button
      type="button"
      disabled
      className="w-full border-r border-t border-b text-base font-medium rounded-r-md text-white bg-blue-500 px-4 py-2 cursor-not-allowed"
    >
      Reset
    </button>
  </div>
);

export default TimerButtonsDisabled;
