import React, { FC } from "react";

type Props = {
  label: string;
};

const Button: FC<Props> = ({ label }) => (
  <button
    type="button"
    disabled
    className="w-full border text-base font-medium rounded-md text-white bg-blue-500 px-4 py-2 cursor-not-allowed"
  >
    {label}
  </button>
);

export default Button;
