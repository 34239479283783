import React, { FC, useState, useRef, useCallback, useEffect } from "react";
import TimerButtons from "components/morecules/TimerButtons";
import Timer from "components/morecules/Timer";
import TextBox from "components/atoms/TextBox";
import TimerButtonsDisabled from "components/atoms/TimerButtonsDisabled";
import { UseFormRegister, FieldValues } from "react-hook-form";

type Props = {
  id: number;
  register: UseFormRegister<FieldValues>;
};

const MiddleReading: FC<Props> = ({ id, register }) => {
  const [active, setActive] = useState(true);
  const disableMiddleReading = () => setActive(false);

  const middleReadingElement = useRef<HTMLDivElement>(null);
  const scrollToMiddleReadingElement = useCallback(() => {
    middleReadingElement.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [middleReadingElement]);

  useEffect(() => {
    scrollToMiddleReadingElement();
  }, [scrollToMiddleReadingElement]);

  if (active) {
    return (
      <div
        id={`middleReadingElement-${id}`}
        ref={middleReadingElement}
        className="flex-none relative rounded-lg shadow-xl bg-white p-4"
      >
        <h2 className="text-lg leading-6 font-medium text-black">読書中</h2>
        <Timer disableMiddleReading={disableMiddleReading} />
        <TextBox
          placeholder="読んでいる間のメモ"
          register={register}
          name={`middleReadingElement_${id}`}
        />
        <TimerButtons />
      </div>
    );
  }

  return (
    <div
      id={`middleReadingElement-${id}`}
      className="flex-none relative rounded-lg shadow-xl bg-white p-4"
    >
      <h2 className="text-lg leading-6 font-medium text-black">読書中</h2>
      <div className="font-sans text-5xl font-bold text-gray-400">00:00</div>
      <TextBox
        placeholder="読んでいる間のメモ"
        register={register}
        name={`middleReadingElement_${id}`}
      />
      <TimerButtonsDisabled />
    </div>
  );
};

export default MiddleReading;
