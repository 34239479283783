import { createContext, Dispatch, Reducer } from "react";
import TimerOperations from "utils/timerOperations";

type TimerAction = {
  type: "START" | "STOP" | "RESET" | "TICK";
};

type TimerState = {
  timer: TimerOperations;
};

type IContext = {
  state: TimerState;
  dispatch: Dispatch<TimerAction>;
};

export const TimerContext = createContext<IContext>({
  state: {
    // This value is not used because timer is initialized before used.
    timer: new TimerOperations(30 * 60 * 1000),
  },
  dispatch: () => null,
});

export const timerReducer: Reducer<TimerState, TimerAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "START":
      state.timer.start();
      break;
    case "STOP":
      state.timer.stop();
      break;
    case "RESET":
      state.timer.reset();
      break;
    case "TICK":
      state.timer.tick();
      break;
    default:
      state.timer.reset();
      break;
  }
  return { ...state };
};
