import React, {
  FC,
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from "react";
import TextBox from "components/atoms/TextBox";
import { TimeLineContext } from "contexts/timeLineContext";
import { TimerContext } from "contexts/timerContext";
import Button from "components/atoms/Button";
import ButtonDisabled from "components/atoms/ButtonDisabled";
import { UseFormRegister, FieldValues } from "react-hook-form";

type Props = {
  register: UseFormRegister<FieldValues>;
};

const BeforeReading: FC<Props> = ({ register }) => {
  const [active, setActive] = useState(true);

  const timeLineDispatch = useContext(TimeLineContext).dispatch;
  const timerDispatch = useContext(TimerContext).dispatch;

  const ok = useCallback(() => {
    timeLineDispatch({ type: "INPUT_BEFORE_READING_NOTE" });
    timerDispatch({ type: "START" });
    setActive(false);
  }, [timeLineDispatch, timerDispatch, setActive]);

  const beforeReadingElement = useRef<HTMLDivElement>(null);
  const scrollToBeforeReadingElement = useCallback(() => {
    beforeReadingElement.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [beforeReadingElement]);

  useEffect(() => {
    scrollToBeforeReadingElement();
  }, [scrollToBeforeReadingElement]);

  if (active) {
    return (
      <div
        id="beforeReadingElement"
        ref={beforeReadingElement}
        className="flex-none relative rounded-lg shadow-xl bg-white p-4"
      >
        <h2 className="text-lg leading-6 font-medium text-black ">読書計画</h2>
        <TextBox
          placeholder="読む前の計画・メモ"
          register={register}
          name="beforeReadingElement"
        />
        <Button label="Start" onclick={ok} />
      </div>
    );
  }

  return (
    <div
      id="beforeReadingElement"
      className="flex-none relative rounded-lg shadow-xl bg-white p-4"
    >
      <h2 className="text-lg leading-6 font-medium text-black ">読書計画</h2>
      <TextBox
        placeholder="読む前の計画・メモ"
        register={register}
        name="beforeReadingElement"
      />
      <ButtonDisabled label="Start" />
    </div>
  );
};

export default BeforeReading;
