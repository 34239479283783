import React, { FC, useEffect, useContext, useCallback } from "react";
import { TimerContext } from "contexts/timerContext";
import { TimeLineContext } from "contexts/timeLineContext";

const formatTime = (duration: number): string => {
  if (duration < 0) {
    return "00:00";
  }

  const minutes = Math.floor(duration / (60 * 1000)) % 60;
  const seconds = Math.floor(duration / 1000) % 60;

  const m = `00${minutes}`.slice(-2);
  const s = `00${seconds}`.slice(-2);

  return `${m}:${s}`;
};

const Timer: FC<{ disableMiddleReading: () => void }> = ({
  disableMiddleReading,
}) => {
  const { state, dispatch } = useContext(TimerContext);
  const timeLineDispatch = useContext(TimeLineContext).dispatch;

  const tick = useCallback(() => dispatch({ type: "TICK" }), [dispatch]);

  // Countdown
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (state.timer.isTimerRunning) {
      const timerId = setInterval(tick, 10);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [state, tick]);

  // Timeup
  useEffect(() => {
    if (state.timer.last <= 0) {
      dispatch({ type: "RESET" });
      timeLineDispatch({ type: "TIMEOUT" });
      disableMiddleReading();
    }
  }, [dispatch, timeLineDispatch, disableMiddleReading, state.timer.last]);

  return (
    <div className="font-sans text-5xl font-bold">
      {formatTime(state.timer.last)}
    </div>
  );
};

export default Timer;
