const initialLimitParser = (param: string | string[] | null): number => {
  const limit = Number(param);
  // 5999 is 99:59
  if (!Number.isNaN(limit) && limit >= 1 && limit <= 5999) {
    return limit;
  }
  return 30 * 60;
};

export default initialLimitParser;
