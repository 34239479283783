class TimerOperations {
  isTimerRunning: boolean;

  /**
   * @property {boolean} isTimerInitialized タイマーの初期化が必要かどうかを示す。
   * ここでの初期化とは、現在時刻に基づき due をセットすることを指している。
   * タイマーが最初に開始されるときと、リセットされた後に開始されるときに、
   * その時点での due がセットされる必要がある。
   * もしそうしないと、アプリ開始時点、またはリセットされた時点での due が
   * タイマーの基準となってしまい、表示される時間がずれてしまう。
   */
  isTimerInitialized: boolean;

  private readonly limit: number;

  due: Date;

  last: number;

  constructor(limit: number) {
    this.isTimerRunning = false;
    this.isTimerInitialized = false;
    this.limit = limit;

    const nowTime = new Date().getTime();
    this.due = new Date(nowTime + limit);
    this.last = this.calcLast();
  }

  private calcLast(): number {
    const now = new Date();
    return this.due.getTime() - now.getTime();
  }

  private updateDueBasedOnNow(): void {
    const nowTime = new Date().getTime();
    this.due = new Date(nowTime + this.last);
  }

  reset(): void {
    this.isTimerRunning = false;
    this.isTimerInitialized = false;
    this.last = this.limit;
    this.updateDueBasedOnNow();
  }

  private updateLast(): void {
    this.last = this.calcLast();
  }

  stop(): void {
    this.isTimerRunning = false;
  }

  start(): void {
    if (!this.isTimerInitialized) {
      this.reset();
    } else {
      this.updateDueBasedOnNow();
    }
    this.isTimerRunning = true;
    this.isTimerInitialized = true;
  }

  tick(): void {
    this.updateLast();
  }
}

export default TimerOperations;
